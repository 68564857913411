import {
  Box, Button, Divider, Flex, Heading, Icon, Text, useToast,
} from '@chakra-ui/react';
import { Fragment, useState } from 'react';
import { FiChevronLeft } from 'react-icons/fi';
import { useGetBasketQuery } from '../../lib/graphql';
import Alerter from '../alerter';
import LoginForm from './loginForm';
import RegisterForm from './registerForm';

interface IFormProps {
  onSuccess: () => void
}

const AuthForm = ({ onSuccess } : IFormProps): JSX.Element => {
  const [showRegistration, setShowRegistration] = useState<boolean>(false);
  const [alerterItems, setAlerterItems] = useState<IAlerterItem[]>([]);
  const toast = useToast();

  const { ...basket } = useGetBasketQuery();

  async function success() {
    await basket.refetch();
    toast({
      title: 'Logged in',
      duration: 2000,
      position: 'top-right',
      status: 'success',
    });
    onSuccess();
  }

  function close() {
    onSuccess();
  }

  function onError(err: IAlerterItem) {
    setAlerterItems(alerterItems.concat(err));
  }

  return (
    <Box mb={6}>
      <Alerter alerterItems={alerterItems} />
      { !showRegistration
        ? (
          <>
            <Heading as="h3" size="lg" mt={2} mb={8}>Login</Heading>
            <LoginForm onSuccess={success} onError={onError} onClose={close} />
            <Divider mt={6} mb={6} />
            <Text textAlign="center" mb={4}>Don&apos;t have an account yet?</Text>
            <Flex alignItems="center" justifyContent="center">
              <Button onClick={() => setShowRegistration(true)} colorScheme="yellow">
                Register
              </Button>
            </Flex>
          </>
        )
        : (
          <>
            <Button onClick={() => setShowRegistration(false)} variant="link" leftIcon={<Icon as={FiChevronLeft} margin-bottom="5px" />}>
              Login
            </Button>
            <Heading as="h3" size="lg" mt={2} mb={8}>Register</Heading>
            <RegisterForm onSuccess={success} onError={onError} />
          </>
        )}
    </Box>
  );
};

export default AuthForm;
