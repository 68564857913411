import {
  Box,
  Button, Flex, FormControl, FormErrorMessage, Icon, Input, InputGroup, InputRightElement,
} from '@chakra-ui/react';
import {
  useState,
} from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FiEye, FiEyeOff } from 'react-icons/fi';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useAuth } from '../../pages/api/userAuth';

interface IFormProps {
  onSuccess: (resp?: unknown) => void;
  onError: (err: IAlerterItem) => void;
  onClose?: () => void;
}

const schema = yup.object().shape({
  email: yup.string().email().required(),
  password: yup
    .string()
    .required('Please Enter your password'),
});

const LoginForm = ({ onSuccess, onError, onClose }: IFormProps): JSX.Element => {
  const router = useRouter();

  const {
    login,
  } = useAuth();

  const {
    handleSubmit, errors, register, formState,
  } = useForm({
    resolver: yupResolver(schema),
  });

  async function onSubmit(values: Record<string, any>) {
    try {
      login(values.email, values.password)
        .then((resp) => onSuccess(resp))
        .catch((err) => {
          // ERROR LOG
          console.error(err);
          onError({
            message: 'Please check your email and password and try again',
            autoDismiss: true,
            type: 'error',
          });
        });
    } catch (error) {
      console.error(error);
      onError({
        message: 'Please check your email and password and try again',
        autoDismiss: true,
        type: 'error',
      });
    }
  }

  const [show, setShow] = useState(false);
  const handleClick = () => setShow(!show);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormControl isInvalid={errors.email}>
        <Input
          name="email"
          placeholder="Email"
          aria-label="Email address"
          autoComplete="login email"
          type="email"
          ref={register()}
        />
        <FormErrorMessage>
          {errors.email && errors.email.message}
        </FormErrorMessage>
      </FormControl>

      <FormControl isInvalid={errors.password} mt={2}>
        <InputGroup size="md">
          <Input
            name="password"
            pr="4.5rem"
            type={show ? 'text' : 'password'}
            placeholder="Password"
            aria-label="Password"
            ref={register}
            autoComplete="login current-password"
          />
          <InputRightElement width="4.5rem">
            <Button h="1.75rem" size="sm" onClick={handleClick} colorScheme="pink" variant="outline">
              {show ? (<Icon as={FiEye} />) : (<Icon as={FiEyeOff} />)}
            </Button>
          </InputRightElement>
        </InputGroup>
        <FormErrorMessage>
          {errors.password && errors.password.message}
        </FormErrorMessage>
      </FormControl>
      <Button
        mt={4}
        isLoading={formState.isSubmitting}
        type="submit"
        colorScheme="yellow"
      >
        Log in
      </Button>
      <Flex alignItems="center" justifyContent="center" pt={4}>
        <Button variant="link" onClick={() => {
          router.push('/password/request');
          onClose();
        }}>Forgot my password</Button>
      </Flex>
    </form>
  );
};

export default LoginForm;
