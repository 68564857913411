/* eslint-disable react/destructuring-assignment */
import React, {
  createContext, ReactNode
} from 'react';

export type ContextType = {
  paths: {
    root: string,
    courses: string,
    events: string
  }
  isCourseSite: boolean,
  isEventsSite: boolean
}

type INavProviderProps = {
  children: ReactNode
}

const NavigationContext = createContext<ContextType>(undefined);

const NavigationProvider = ({ children }: INavProviderProps): any => {
  const isEventsSite = process.env.SITE === 'EVENTS'
  const isCourseSite = process.env.SITE === 'COURSES'

  const paths = {
    root: isEventsSite ? '/events-booking' : '/class-booking',
    courses: isCourseSite ? '/class-booking' : `https://${process.env.COURSE_HOST}`,
    events: isEventsSite ? '/events-booking' : `https://${process.env.EVENTS_HOST}`
  }

  return (
    <NavigationContext.Provider value={{
      paths,
      isCourseSite,
      isEventsSite
    }}
    >
      {children}
    </NavigationContext.Provider>
  );
};

export { NavigationProvider, NavigationContext };
